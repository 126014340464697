import request from '../config/request';
import axios from 'axios';
import env from '../env';

export const UploadProfileAPI = async (body: any) => {
  const token = localStorage.getItem('salon-admin-token');

  const response = await axios.post(
    `${env.app.backend_url}${env.app.api_prefix_email}/get-file-upload-link`,
    body,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );

  return response;
};
export const UploadBankPDFAPI = async (body: any) => {
  const token = localStorage.getItem('salon-admin-token');

  const response = await axios.post(
    `${env.app.backend_url}${env.app.api_prefix_email}/get-bank-document-upload-link`,
    body,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response;
};

export const EmailVerifyForAppAPI = async (body: any) => {
  const response = await request({
    method: 'PUT',
    url: `/verify-email`,
    body,
  });
  return response;
};

export const GetNotificationAPI = async (params: any) => {
  const token = localStorage.getItem('salon-admin-token');
  const response = await axios.get(
    `${env.app.backend_url}${env.app.api_prefix_email}/notification/admin`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params,
    },
  );
  return response;
};

export const GetOffdaysListAPI = async (params: any) => {
  const token = localStorage.getItem('salon-admin-token');
  const response = await axios.get(
    `${env.app.backend_url}${env.app.api_prefix}/offdays/list/${params.providerId}`,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
      params,
    },
  );
  return response;
};

export const UpdateOffdaysListAPI = async (body: any) => {
  const token = localStorage.getItem('salon-admin-token');
  const response = await axios.put(
    `${env.app.backend_url}${env.app.api_prefix}/offdays/update/${body.id}`,
    body,
    {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    },
  );
  return response;
};

// TODO just for testing apple pay workaround
export const validatePayment = async (payload: { checkoutId: string }) => {
  const { checkoutId } = payload;

  const response = await axios.get(
    `${env.app.backend_url}/api/v1/app/user/booking/confirm-booking-status?id=${checkoutId}&resourcePath=${checkoutId}`,
  );

  return response;
};
